










import { Component, Vue } from "vue-property-decorator";
import GeneralNavBar from '@/components/general/layout/GeneralNavBar.vue';
@Component({
  components: {
    GeneralNavBar
  }
})
export default class PublicPage extends Vue {}
