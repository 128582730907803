












import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class AuthenticationForm extends Vue {
  @Prop()
  formTitle!: string;
}
